.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .instructions {
    animation: show-instructions forwards 1s normal;
  }
  .mobile-home-text {
    animation: show-instructions forwards 1s normal;
  }
  .arrow-cont {
    animation: drop-arrow forwards 1s normal;
  }
  .keys {
    animation: show-instructions forwards 1s normal;
  }
}

@media (max-width: 450px) {
  .game-board {
    display: none;
  }
  .Home {
    display: none;
  }
  .bird-house {
    display: none;
  }
  .headshot {
    z-index: 1;
  }
  .aboutPics {
    min-width: 118%;
  }
  .about-text {
    min-width: 110%;
  }
}

@media (min-width: 451px) {
  .mobile-home {
    display: none;
  }

  .headshot {
    opacity: 0.85;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes drop-arrow {
  from {
    transform: translateY(-200vh);
  }
  to {
    transform: translateY(22vh);
  }
}

@keyframes show-instructions {
  from {
    transform: translateY(-100vh);
  }
  to {
    transform: translateY(0vh);
  }
}

.nav-link:hover {
  color: black;
  text-decoration: underline;
}

.App-body {
  padding-top: 16vh;
  padding-bottom: 13vh;
  min-height: 90vh;
}

.inner1 {
  display: flex;
  flex-direction: row;
  justify-items: space-evenly;
  flex-wrap: wrap;
}

.inner2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-text {
  font-size: calc(58px + 2vmin);
  /* font-family: 'Trocchi', sans-serif; */
  font-family: 'Shizuru', cursive;
  color: #087411af;
  display: inline;
  font-weight: bold;
  /* text-shadow: 0.2vh 0.2vh rgb(138, 212, 200); */
}

.mobile-home-text {
  font-weight: bolder;
  font-size: calc(45px + 2vmin);
}

.game-board {
  position: absolute;
  /* z-index: 2; */
}

/* .mobile-home {
  position: absolute;
} */

.body-top {
  /* position: relative; */
  z-index: 1;
}

.keys {
  align-self: center;
  height: 10vh;
  width: auto;
}

.headshot {
  z-index: 1;
  width: 100%;
}

.bird {
  height: 22vh;
  width: auto;
}

.bird-button {
  margin: 5vh;
}

.arrow {
  height: 40vh;
  width: auto;
}

.arrow-cont {
  align-self: flex-end;
  justify-self: flex-end;
}

.about-header {
  align-self: center;
  font-family: Arial, Helvetica, sans-serif;
  color: #3a3939f1;
  font-weight: bolder;
  white-space: nowrap;
}

.about-text {
  padding: 2.5vh;
  margin: 2.5vh;
  text-align: justify;
  background-color: rgba(202, 223, 231, 0.473);
}

.about-words {
  font-size: 1rem;
  text-align: justify;
}

.fact-pic {
  height: 35vh;
  width: 27vh;
}

.fact-card {
  text-align: center;
  width: 75vw;
  margin: 3vh;
  background-color: rgba(247, 205, 212, 0.603);
}

.project-card {
  border-color: lightsalmon;
  border-style: solid;
  border-width: 0.2vh;
}

.project-slide {
  background-color: rgba(35, 7, 44, 0.432);
  max-width: 90vh;
  margin: 2vh;
}

.project-pic {
  height: 40vh;
  width: 40vh;
  margin: 3vh;
}

.project-words {
  margin-bottom: 5vh;
  text-align: center;
  padding: 2.5vh;
  margin-left: 2.5vh;
  margin-right: 2.5vh;
  color: white;
  font-size: calc(4px + 2vmin);
  white-space: pre-wrap;
}

.body-link {
  position: relative;
  z-index: 2;
}

.footer {
  height: 15vh;
}

.socials {
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.social-link-7 {
  height: 7vh;
  width: 7vh;
}

.social-link-5 {
  height: 5vh;
  width: 5vh;
}

.social-link-3 {
  height: 3vh;
  width: 3vh;
}

.social-button {
  margin-right: 1vh;
}

.bird-house {
  height: 55vh;
  width: auto;
  align-self: flex-end;
}

.form-and-house {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.aboutPics {
  min-width: 250px;
  width: 45%;
  border: 0.75vh solid white;
  border-radius: 1vh;
}

.song {
  width: 90%;
  height: 50vh;
  opacity: 0.8;
}

.dedication {
  padding-top: 1vh;
  font-size: 1.4rem;
  text-align: center;
  color: rgb(51, 78, 82);
  white-space: pre-wrap;
}

.Video {
  width: 100%;
}

.alert {
  margin-top: 10%;
}

.w100 {
  width: 100%;
}

.line-break {
  width: 30vw;
  height: 0.2vh;
  /* background-color: #3a3939be; */
  background-color: rgba(255, 255, 255, 0.815);
  margin: 3vh;
  align-self: center;
}

.w70 {
  width: 70vw;
}

.Pic-wrap {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  position: relative;
  z-index: 1;
  height: auto;
  margin-top: -18%;
  margin-bottom: 5vh;
}

.Wide-pic {
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
